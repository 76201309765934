import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import moduleStyle from "./confirmModal.module.css";
import ExlyModal from "common/Components/ExlyModal";

const ExlySuccessModal = ({
  title,
  description,
  onSuccess,
  successBtnText = "Confirm",
  ...restProps
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <ExlyModal
      modal_props={{ modalPaperClassName: moduleStyle.modal_paper }}
      title={
        <div
          className={
            isDesktop
              ? moduleStyle.desktop_modal_header
              : moduleStyle.mobile_modal_header
          }
        >
          <span>{title}</span>
        </div>
      }
      primaryBtnText={successBtnText}
      onPrimaryBtnClick={onSuccess}
      primaryBtnProps={{
        className: moduleStyle.primary_btn,
      }}
      {...restProps}
    >
      <div
        className={
          isDesktop
            ? moduleStyle.desktop_success_wrapper
            : moduleStyle.mobile_success_wrapper
        }
      >
        <span>{description}</span>
      </div>
    </ExlyModal>
  );
};

export default ExlySuccessModal;
