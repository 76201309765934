import React from "react";
import PropTypes from "prop-types";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlySuccessModal from "../ExlyConfirmationModal";

const SuccessConfirmationModal = ({
  show,
  onClose,
  mediaId,
  handleSuccess,
  title = "Confirm",
  description = "",
}) => {
  return (
    <ExlySuccessModal
      open={show}
      onClose={onClose}
      onSuccess={() => {
        handleSuccess(mediaId);
        onClose();
      }}
      title={title}
      description={description}
    />
  );
};

export default withComponentLibraryTheme(SuccessConfirmationModal);

SuccessConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mediaId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};
